import React from "react"
import MiscContent from "../layouts/MiscContent"
import WhatOurCustomersSay from "../layouts/WhatOurCustomersSay"
import { HomeHero } from "../layouts/HomeHero/home-hero.component"
import InfoSlider from "../layouts/InfoSlider"
import MissionSection from "../layouts/MissionSection"
import StatsSection from "../layouts/StatsSection"
import GallerySlider from "../layouts/GallerySlider"
import LetsChatParallax from "../layouts/LetsChatParallax"
import Testimonials from "../layouts/Testimonials"
import BookDemoSection from "../layouts/BookDemoSection"
import TabsSection from "../layouts/TabsSection"
import { Hero } from "../layouts/Hero/hero.component"
import ArticlesBanner from "../layouts/ArticlesBanner"
import BlogsPage from "../layouts/BlogsPage"

export const getPageLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} />
    case "WhatOurCustomersSay":
      return <WhatOurCustomersSay {...layout} />
    case "HomeHero":
      return <HomeHero {...layout} />
    case "InfoSlider":
      return <InfoSlider {...layout} />
    case "MissionSection":
      return <MissionSection {...layout} />
    case "StatsSection":
      return <StatsSection {...layout} />
    case "GallerySlider":
      return <GallerySlider {...layout} />
    case "LetsChatParallax":
      return <LetsChatParallax {...layout} />
    case "Testimonials":
      return <Testimonials {...layout} />
    case "BookDemoSection":
      return <BookDemoSection {...layout} />
    case "TabsSection":
      return <TabsSection {...layout} />
    case "Hero":
      return <Hero {...layout} />
    case "ArticlesBanner":
      return <ArticlesBanner {...layout} />
    case "BlogsPage":
      return <BlogsPage {...layout} />
  }
}
