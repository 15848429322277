import React, { useState, useEffect } from "react"
import * as S from "./blogs-pagination.styles"
import { Grid } from "@mui/material"
import CustomPagination from "../custom-pagination/custom-pagination.component"
import ArticleCard from "../article-card/article-card.component"

const BlogsPagination = ({ blogs }) => {
  const [blogsToShow, setBlogsToShow] = useState([])
  /*Pagination*/
  const POST_PER_PAGE = 6
  const [paginationCount, setPaginationCount] = useState(0)
  const [page, setPage] = useState(1)
  const [initialBlog, setInitialBlog] = useState(1)
  const [finalBlog, setFinalBlog] = useState(POST_PER_PAGE)

  const handlePaginationChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    setInitialBlog(1)
    setFinalBlog(
      initialBlog + POST_PER_PAGE > blogs?.length
        ? blogs?.length
        : finalBlog + POST_PER_PAGE
    )
  }, [])

  useEffect(() => {
    setBlogsToShow(
      blogs?.slice(page * POST_PER_PAGE - POST_PER_PAGE, page * POST_PER_PAGE)
    )

    setPaginationCount(Math.ceil(blogs?.length / POST_PER_PAGE))

    if (blogs?.length <= POST_PER_PAGE * (page - 1)) {
      setPage(1)
    }
  }, [page, blogs])

  const paginationLocal = {
    currentPage: page,
    numPages: paginationCount,
  }

  if (!blogs) return null

  return (
    <S.Wrapper id="blogs">
      {blogsToShow?.length === 0 && <S.Title>No results found</S.Title>}
      <Grid container spacing={3}>
        {blogsToShow?.map(
          ({ title, excerpt, featuredImage, date, slug }, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={`blog-card-${index}`}>
              <ArticleCard
                title={title}
                excerpt={excerpt}
                featuredImage={featuredImage}
                date={date}
                slug={slug}
              />
            </Grid>
          )
        )}
      </Grid>
      <S.PaginationContainer>
        {POST_PER_PAGE < blogs?.length && (
          <CustomPagination
            scrollTo="blogs"
            pageContext={paginationLocal}
            handlePagination={handlePaginationChange}
          />
        )}
      </S.PaginationContainer>
    </S.Wrapper>
  )
}

export default BlogsPagination
