import React, { useState, useEffect } from "react"
import * as S from "./blogs-filter.styles"
import dayjs from "dayjs"
import {
  Grid,
  InputAdornment,
  NativeSelect,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import useDebounce from "../../hooks/useDebounce"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

const BlogsFilter = ({ setBlogsToShow, blogs, allBlogs }) => {
  const [searchValue, setSearchValue] = useState("")
  const debouncedSearchTerm = useDebounce(searchValue, 1000)
  const [openInput, setOpenInput] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState("newest")
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down("md"))

  const filterData = val => {
    if (!val) {
      setBlogsToShow(blogs)
      return
    }

    setBlogsToShow(
      allBlogs?.filter(blog => {
        if (blog?.title?.toLowerCase().includes(val.toLowerCase().trim())) {
          return blog
        } else {
          return blog?.postBuilder?.layouts?.some(({ content }) =>
            content?.toLowerCase().includes(val.toLowerCase().trim())
          )
        }
      })
    )
  }

  useEffect(() => {
    let auxBlogs = blogs

    auxBlogs.sort((a, b) => {
      const dateA = +dayjs(a.date)
      const dateB = +dayjs(b.date)
      return selectedFilter === "newest" ? dateA - dateB : dateB - dateA
    })

    setBlogsToShow([...auxBlogs])
  }, [selectedFilter])

  useEffect(() => {
    if (debouncedSearchTerm) {
      filterData(debouncedSearchTerm)
    } else {
      filterData(null)
    }
  }, [debouncedSearchTerm])

  return (
    <S.Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <S.Subtitle>Latest news and articles</S.Subtitle>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={4.5}>
          <S.InputWrapper>
            <S.SearchInput
              className={openInput ? "openInput" : ""}
              fullWidth
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder="Search..."
              variant="standard"
            />
            {isMd ? (
              <S.CustomSearchIcon />
            ) : openInput ? (
              <S.CustomCloseSearchIcon onClick={() => setOpenInput(false)} />
            ) : (
              <S.CustomSearchIcon onClick={() => setOpenInput(true)} />
            )}
          </S.InputWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={1.5}>
          <S.Select
            id="outlined-select-currency-native"
            select
            fullWidth
            value={selectedFilter}
            onChange={event => {
              setSelectedFilter(event.target.value)
            }}
            SelectProps={{
              native: true,
            }}
          >
            <option value="newest">Newest first</option>
            <option value="oldest">Oldest first</option>
          </S.Select>
        </Grid>
      </Grid>
    </S.Wrapper>
  )
}

export default BlogsFilter
